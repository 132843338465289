<template>
  <div>
    <title-bar :title-stack="titleStack" />
    <hero-bar>
      Request
      <router-link slot="right" to="/" class="button">
        Dashboard
      </router-link>
    </hero-bar>
    <section class="section is-main-section">
      <card-component>
        <em>
          <p class="pb-2">Send an DescriptionRequestMessage to a specific to retrieve the Self-Description of that Connector, or an specific element of the Self-Description of that Connector.</p>
          <p>The response can be used, in the "Parsed View", to fill in the parameters for either the <router-link to="/artifacts/consumer">Artifact Request</router-link> or <router-link to="/artifacts/consumer">Contract Request</router-link> forms.</p>
        </em>
      </card-component>
      <card-component title="Request Self Description" icon="search" headerColor="primary">
        <form @submit.prevent="requestDescription">
          <b-field label="Connector ID" horizontal>
            <b-input
              v-model="request.connectorId"
              placeholder="IDS ID (URI)"
              validation-message="Please enter a URI (https:// or urn:)"
              type="url"
              required
            />
          </b-field>
          <b-field label="Agent ID" horizontal>
            <b-input
              v-model="request.agentId"
              placeholder="Agent IDS ID (URI) (optional)"
              validation-message="Please enter a URI (https:// or urn:)"
              type="url"
            />
          </b-field>
          <b-field label="Access URL" horizontal>
            <b-input
              v-model="request.accessUrl"
              placeholder="Connector Access URL (URI)"
              validation-message="Please enter a URI (https:// or urn:)"
              type="url"
              required
            />
          </b-field>
          <b-field label="Requested Element" horizontal>
            <b-input
              v-model="request.requestedElement"
              placeholder="Requested Element ID (URI) (optional)"
              validation-message="Please enter a URI (https:// or urn:)"
              type="url"
            />
          </b-field>
          <b-field label="Response content type" horizontal>
            <b-radio v-model="accept"
                name="name"
                native-value="application/ld+json">
                JSON-LD
            </b-radio>
            <b-radio v-model="accept"
                name="name"
                native-value="multipart/form-data">
                MIME Multipart
            </b-radio>
          </b-field>
          <hr />
          <b-field horizontal>
            <b-field grouped>
              <div class="control">
                <b-button native-type="submit" type="is-primary"
                  >Request description</b-button
                >
              </div>
              <div class="control">
                <b-button type="is-primary is-outlined" @click="()=>{request = {connectorId: null, agentId: null, accessUrl: null, requestedElement: null }}"
                  >Reset</b-button
                >
              </div>
            </b-field>
          </b-field>
          <template v-if="description">
            <hr />
            <h3 class="title">Response</h3>
            <self-description :data="description" />
          </template>
        </form>
      </card-component>
    </section>
  </div>
</template>

<script>
import TitleBar from '@/components/TitleBar'
import CardComponent from '@/components/CardComponent'
import HeroBar from '@/components/HeroBar'
import { mapState } from 'vuex'
import SelfDescription from '@/components/ParsedSelfDescription'
export default {
  name: 'DescriptionRequest',
  components: {
    HeroBar,
    CardComponent,
    TitleBar,
    SelfDescription
  },
  data () {
    return {
      request: {
        connectorId: localStorage.getItem('connectorId'),
        accessUrl: localStorage.getItem('accessUrl'),
        agentId: localStorage.getItem('agentId'),
        requestedElement: localStorage.getItem('requestedElement')
      },
      description: null,
      accept: 'application/ld+json'
    }
  },
  computed: {
    titleStack () {
      return ['Self Description', 'Request']
    },
    ...mapState(['api'])
  },
  methods: {
    async requestDescription () {
      localStorage.setItem('connectorId', this.request.connectorId || '')
      localStorage.setItem('accessUrl', this.request.accessUrl || '')
      localStorage.setItem('agentId', this.request.agentId || '')
      localStorage.setItem('requestedElement', this.request.requestedElement || '')
      try {
        const description = await this.api.get('/description', {
          params: this.request,
          headers: {
            Accept: this.accept
          }
        })
        this.description = description.data
      } catch (e) {
        if (e.response) {
          this.description = `HTTP Error: ${e.response.status}

${JSON.stringify(e.response.data)}`
        } else if (e.request) {
          this.description = `HTTP Request Error:
${JSON.stringify(e.request)}`
        } else {
          this.description = `Unexpected Error: ${e.message}`
        }
      }
    }
  }
}
</script>
