<template>
  <div>
    <b-field v-if="typeof data === 'object' && data['@type'].endsWith('Connector')">
      <b-switch v-model="parsedView">
          Parsed View
      </b-switch>
    </b-field>
    <template v-if="parsedView && typeof data === 'object' && data['@type'].endsWith('Connector') ">
      <b-field label="IDS ID" horizontal>
        <p class="control">
          {{ data['@id'] }}
        </p>
      </b-field>
      <b-field label="Title" horizontal>
        <div class="control">
          <p class="control" v-for="(title, idx) in data['ids:title']" :key="`title${idx}`">
            {{ title['@value'] }}
          </p>
        </div>
      </b-field>
      <b-field label="Description" horizontal>
        <div class="control">
          <p class="control" v-for="(description, idx) in data['ids:description']" :key="`description${idx}`">
            {{ description['@value'] }}
          </p>
        </div>
      </b-field>
      <b-field label="Security Profile" horizontal>
        <p class="control">
          {{ data['ids:securityProfile']['@id'] }}
        </p>
      </b-field>
      <b-field label="Curator" horizontal>
        <p class="control">
          {{ data['ids:curator']['@id'] }}
        </p>
      </b-field>
      <b-field label="Maintainer" horizontal>
        <p class="control">
          {{ data['ids:maintainer']['@id'] }}
        </p>
      </b-field>
      <b-field label="Endpoints" horizontal>
        <div class="control">
          <p class="control" v-for="(endpoint, idx) in data['ids:hasEndpoint']" :key="`endpoint${idx}`">
            {{ endpoint['ids:accessURL']['@id'] }}
            <template v-if="endpoint['ids:accessURL']['@id'] === data['ids:hasDefaultEndpoint']['ids:accessURL']['@id']"><em>Default</em></template>
          </p>
        </div>
      </b-field>
      <b-field label="Catalogs" horizontal>
        <div class="control">
          <p class="control" v-for="(catalog, idx) in data['ids:resourceCatalog']" :key="`catalog${idx}`">
            <a @click="(selectedCatalog === catalog) ? selectedCatalog = null : selectedCatalog = catalog" :class="{'has-text-weight-bold': selectedCatalog === catalog}">
              {{ catalog['@id'] }}
              (<template v-if="catalog['ids:offeredResource']">{{ catalog['ids:offeredResource'].length }}</template><template v-else>0</template> offered resources, <template v-if="catalog['ids:requestedResource']">{{ catalog['ids:requestedResource'].length }}</template><template v-else>0</template> requested resources)
            </a>
          </p>
        </div>
      </b-field>
      <template v-if="selectedCatalog">
      <b-field label="Offered resources" horizontal>
        <div class="control">
          <!-- <p class="control" v-for="(resource, idx) in selectedCatalog['ids:offeredResource']" :key="`offeredresource${idx}`">
            {{ resource['@id'] }}
          </p> -->
                  <b-table
          :loading="isLoading"
          :paginated="paginated"
          :per-page="15"
          :striped="true"
          :hoverable="true"
          default-sort="name"
          :data="selectedCatalog['ids:offeredResource']"
        >
          <b-table-column label="Resource ID" field="@id" sortable v-slot="props">
            {{ props.row['@id'] }}
          </b-table-column>
          <b-table-column label="Title" field="ids:title" sortable v-slot="props">
            {{ props.row['ids:title'][0]['@value'] }}
          </b-table-column>
          <b-table-column label="Description" field="ids:description" sortable v-slot="props">
            {{ props.row['ids:description'][0]['@value'] }}
          </b-table-column>
          <b-table-column label="Info" v-slot="props">
            <b-tooltip position="is-bottom">
              <b-icon
                icon="file-search"
                type="is-success"
                class="is-clickable"
                @click.native="resourceModal = {isActive: true, resource: props.row}"
              >
              </b-icon>
              <template v-slot:content>
                Show full resource metadata
              </template>
            </b-tooltip>
            <b-tooltip position="is-bottom" v-if="props.row['ids:contractOffer'] && props.row['ids:contractOffer'][0]">
              <b-icon
                icon="lock"
                type="is-info"
                class="is-clickable"
                @click.native="contractOfferModal = {isActive: true, offer: props.row['ids:contractOffer'][0]}"
              >
              </b-icon>
              <template v-slot:content>
                Show contract offer
              </template>
            </b-tooltip>
          </b-table-column>
          <b-table-column label="Actions" v-slot="props">
            <b-tooltip position="is-bottom">
              <router-link
                to="/artifacts/consumer"
                :artifact="props.row['ids:representation'][0]['ids:instance'][0]['@id']"
                :connectorId="data['@id']"
                :accessUrl="data['ids:hasDefaultEndpoint']['ids:accessURL']['@id']"
                >
                <b-icon
                  icon="download"
                  type="is-success"
                  class="is-clickable"
                  @click.native="requestArtifact(props.row)"
                >
                </b-icon>
              </router-link>
              <template v-slot:content>
                Request artifact
              </template>
            </b-tooltip>
            <b-tooltip position="is-bottom" v-if="props.row['ids:contractOffer'] && props.row['ids:contractOffer'][0]">
              <b-icon
                icon="lock"
                type="is-info"
                class="is-clickable"
                @click.native="requestContract(props.row)"
              >
              </b-icon>
              <template v-slot:content>
                Request contract
              </template>
            </b-tooltip>
          </b-table-column>
          <section slot="empty" class="section">
            <div class="content has-text-grey has-text-centered">
              <p>No offered resources...</p>
            </div>
          </section>
        </b-table>
        </div>
      </b-field>
      </template>
    </template>
    <template v-else>
      <vue-json-editor v-if="dataShadow" v-model="dataShadow" :modes="['view', 'text']" mode="view" :show-btns="false" :expandedOnStart="true"></vue-json-editor>
    </template>
    <b-modal :active.sync="contractOfferModal.isActive" has-modal-card>
      <div class="modal-card" style="width: auto">
        <header class="modal-card-head">
          <p class="modal-card-title">Contract Offer</p>
        </header>
        <section class="modal-card-body">
          <vue-json-editor v-model="contractOfferModal.offer" :modes="['view', 'text']" mode="view" :show-btns="false" :expandedOnStart="true"></vue-json-editor>
        </section>
        <footer class="modal-card-foot">
          <button class="button" type="button" @click="contractOfferModal.isActive = false">Close</button>
        </footer>
      </div>
    </b-modal>
    <b-modal :active.sync="resourceModal.isActive" has-modal-card>
      <div class="modal-card" style="width: auto">
        <header class="modal-card-head">
          <p class="modal-card-title">Resource Offer</p>
        </header>
        <section class="modal-card-body">
          <vue-json-editor v-model="resourceModal.resource" :modes="['view', 'text']" mode="view" :show-btns="false" :expandedOnStart="true"></vue-json-editor>
        </section>
        <footer class="modal-card-foot">
          <button class="button" type="button" @click="resourceModal.isActive = false">Close</button>
        </footer>
      </div>
    </b-modal>
  </div>
</template>

<script>
import vueJsonEditor from 'vue-json-editor'

export default {
  name: 'ParsedSelfDescription',
  components: {
    vueJsonEditor
  },
  props: {
    data: {
      required: true
    }
  },
  computed: {
    dataShadow () {
      return this.data
    }
  },
  data () {
    return {
      parsedView: true,
      selectedCatalog: null,
      isLoading: false,
      paginated: true,
      contractOfferModal: {
        isActive: false,
        offer: null
      },
      resourceModal: {
        isActive: false,
        resource: null
      }
    }
  },
  methods: {
    requestArtifact (resource) {
      this.$router.push({
        name: 'artifactconsumer',
        params: {
          artifact: resource['ids:representation'][0]['ids:instance'][0]['@id'],
          agentId: resource['ids:sovereign']?.['@id'],
          connectorId: this.data['@id'],
          accessUrl: this.extractAccessUrl(resource),
          filename: resource['ids:representation'][0]['ids:instance'][0]['ids:fileName']
        }
      })
    },
    requestContract (resource) {
      this.$router.push({
        name: 'artifactconsumer',
        params: {
          artifact: resource['ids:representation'][0]['ids:instance'][0]['@id'],
          agentId: resource['ids:sovereign']?.['@id'],
          connectorId: this.data['@id'],
          accessUrl: this.extractAccessUrl(resource),
          filename: resource['ids:representation'][0]['ids:instance'][0]['ids:fileName'],
          offer: JSON.stringify(resource['ids:contractOffer'][0], null, 2)
        }
      })
    },
    extractAccessUrl (resource) {
      if (resource['ids:resourceEndpoint']?.[0]) {
        var accessURL = resource['ids:resourceEndpoint'][0]['ids:accessURL']['@id']
        if (resource['ids:resourceEndpoint'][0]['ids:path']) {
          accessURL += resource['ids:resourceEndpoint'][0]['ids:path']
        }
        return accessURL
      } else {
        return this.data['ids:hasDefaultEndpoint']['ids:accessURL']['@id']
      }
    }
  }
}
</script>
